<template>
  <div v-loading="loaders.form" class="px-2 pt-4 pb-4">
    <div class="row m-0 pl-3 pb-2">
      <h1 class="page-title">Online Seminar Manager</h1>
    </div>
    <div class="row m-0">
      <div class="col-12">
        <card class="pb-1">
          <el-form :model="form" ref="onlineSeminarForm" :rules="rules">
            <transition>
            <div v-if="this.rules.url[0].required" id="urlContainer" class="col-6 mt-3">
              <label class="label-text">URL*</label>
              <el-form-item prop="url">
                <el-input placeholder="Address" v-model="form.url"></el-input>
              </el-form-item>
            </div>
            </transition>
            <div class="col-6 mt-3 pt-2">
              <label class="label-text">Use Website Data</label>
              <div class="row">
                <div class="col-6">
                  <el-checkbox class="category-box mt-2 pt-3 pl-3" label="1" :value="form.web_data" @change="web_dataChange">Yes</el-checkbox>
                </div>
                <div class="col-6">
                  <el-checkbox class="category-box mt-2 pt-3 pl-3" label="0" :value="!form.web_data" @change="web_dataChange">No</el-checkbox>
                </div>
              </div>
            </div>
            <transition>
            <div v-if="this.rules.videoCode[0].required" id="videoCodeContainer" class="col-6 mt-3 pt-2 mb-5">
              <label class="label-text">Video Code*</label>
              <el-form-item prop="videoCode">
                <el-input id="videoCodeArea" type="textarea" v-model="form.videoCode" class="w-100"
                          :autosize="{ minRows: 5, maxRows: 7}"
                >
                </el-input>
              </el-form-item>
            </div>
            </transition>
          </el-form>
        </card>
      </div>
    </div>
    <el-button type="primary"
               class="ml-3 px-5 py-3 btn-primary-action font-weight-bold"
               @click="handleClick"
               :disabled="loaders.form"
    >Save</el-button>
  </div>
</template>

<script>
export default {
  name: "OnlineSeminar",
  computed: {

  },
  data() {
    // Custom Validation
    const validateHttps = (rule, value, callback) => {
      if (!(/^https:\/\/(.*)/.test(value)) && this.rules.videoCode[0].required){
        callback(new Error('Please enter https url'))
      }else {
        callback()
      }
    };

    const validateIframeOrHttps = (rule, value, callback) => {
      if (!((/^https:\/\/(.*)/.test(value)) || (/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/.test(value)))
        && this.rules.videoCode[0].required) {
        callback(new Error('Please enter valid video code or https url'))
      } else {
        callback()
      }
    };

    return {
      form: {
        url: '',
        web_data: true,
        videoCode: ''
      },
      loaders: {
        form: false
      },
      rules: {
        url: [
          {required: true, message: 'Please enter the URL', trigger: 'blur'},
          {type: 'url', message: 'Must be a valid url', trigger: 'blur'},
          {validator: validateHttps, trigger: 'blur'}
        ],
        videoCode: [
          {required: true, message: 'Please enter valid video code or https url', trigger: 'blur'},
          {validator: validateIframeOrHttps, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    handleClick() {
      let isValid = true
      this.$refs['onlineSeminarForm'].validate((valid) => {
        if (!valid) {
          isValid = false
          return isValid
        }
      })
      if (isValid) this.updateRequest()
    },
    web_dataChange() {
      this.form.web_data = !this.form.web_data
      this.rules.videoCode[0].required = !this.form.web_data
      this.rules.url[0].required = this.form.web_data
    },
    assignData(data) {
      this.form.web_data = !!data.is_facebook
      this.rules.videoCode[0].required = !data.is_facebook
      this.rules.url[0].required = !!data.is_facebook
      this.form.url = data.url
      this.form.videoCode = (data.sub_content) ? data.sub_content.content : ''
    },
    fetchRequest() {
      this.loaders.form = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/online-seminar')
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.assignData(response.data.data)
        })
        .catch((error) => this.$notify.error({
          title: 'Error',
          message: 'Something went wrong please try again later'
        }))
        .finally(() => this.loaders.form = false)
    },
    updateRequest() {
      this.loaders.form = true
      axios.post(this.$store.getters.getBaseUrl + '/api/content/online-seminar/create', this.form)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.assignData(response.data.data)
          this.$notify.success({
            title: 'Success',
            message: 'Updated successfully. Always check to see how your changes look in the Baritastic app.'
          })
        })
        .catch((error) => this.$notify.error({
          title: 'Error',
          message: 'Something went wrong please try again later'
        }))
        .finally(() => this.loaders.form = false)
    },
  },
  mounted() {
    this.fetchRequest()
  },
}
</script>

<style scoped>

.category-box {
  width: 100%;
  height: 57px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.label-text{
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

#videoCodeContainer{
  opacity: 1;
  height: 150px;
}

#urlContainer{
  opacity: 1;
  height: 70px;
}

</style>
